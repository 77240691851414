import React from "react"
import warenbewegungenHeader from "../../../assets/images/products/netstorsys/transportauftraege_header.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import Outlook from "../Outlook"

const Warenbewegungen = () => {
  return (
    <>
      <section className="services-details-area pt-100" style={{ marginBottom: "3em" }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={warenbewegungenHeader} alt="Wareneingang" />
              </div>

              <div className="">
                <span className="products-sub-title">Qualität im Griff, von Anfang an</span>
                <h1 className={"pt-3 text-break"}>Wareneingang & Qualitätssicherung mit NETSTORSYS</h1>
                <p>
                  Steigende Warenmengen und komplexe Lieferketten machen den manuellen Wareneingang zeitaufwendig und
                  fehleranfällig. Unser Lagerverwaltungssystem (LVS) digitalisiert und optimiert ihn. <b>Sichern Sie
                  verlässlich die Qualität Ihrer Produkte, lagern Sie schneller ein und minimieren Sie Fehler. </b>
                </p>
                <h2>Schnell und zuverlässig prüfen</h2>
                <p>
                  Das WMS gleicht die Avisdaten einer Lieferung mit den Bestellungen ab und plant den Wareneingang im
                  System. Die Lageristen identifizieren die eingetroffene Ware per Barcode-Scan. Das System liest
                  Lieferscheine automatisiert ein, vergleicht die Daten und startet den Prüfprozess anhand hinterlegter
                  Prüfpläne. Es assistiert bei der Kontrolle von Qualität, Mengen oder Mindesthaltbarkeitsdatum und gibt
                  Ihrem Team klare Anweisungen.
                </p>
                <h2>Nahtlos integriert, lückenlos dokumentiert</h2>
                <p>
                  NETSTORSYS ist nahtlos an Ihre ERP- oder Warenwirtschaftssysteme angebunden. Alle Daten werden zentral
                  gespeichert und sind jederzeit abrufbar. Jeder Prüfschritt wird digital dokumentiert. Das LVS erstellt
                  automatisch Prüfberichte und Wareneingangsprotokolle. Im Reklamationsfall können Sie so schnell
                  reagieren.
                </p>
                <h2>Lagerbestände und Lieferbarkeit auf Knopfdruck</h2>
                <p>
                  Alle relevanten Wareneingangsdaten werden digital per Scan erfasst und sofort im System hinterlegt.
                  Die Software aktualisiert den Lagerbestand in Echtzeit. Kunden und Mitarbeiter sind aktuell über die
                  Verfügbarkeit von Artikeln und Komponenten informiert, können Waren bestellen, sie für Aufträge und
                  Fertigung reservieren.
                </p>
                <h2>Intelligent einlagern</h2>
                <p>
                  Basierend auf Lagerstrategie, Artikelgruppe oder Zugriffshäufigkeit weist NETSTORSYS Artikeln optimale
                  Lagerplätze zu, erstellt Transportaufträge, fasst diese zu laufwegsoptimierten Einlagerbatches
                  zusammen und ordnet sie Mitarbeitern zu. Diese transportieren die Ware und bestätigen den
                  Einlagerungsvorgang, indem sie Artikel und Lagerplatz scannen. Jede Warenbewegung ist lückenlos
                  rückverfolgbar – und Ihr Lager perfekt aufgestellt.
                </p>
                <Outlook
                  text={"Zuverlässig einlagern, Qualität sichern und Zeit gewinnen. Mit NETSTORSYS automatisieren Sie die tägliche Routine beim Wareneingang, minimieren manuelle Eingriffe, Personalaufwand, Kosten und Fehlerquote."} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Warenbewegungen
