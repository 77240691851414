import React from "react"
import CallToAction from "./CallToAction"

const Outlook = ({ text = "" }) => {

  return (
    <>
      <p style={{
        margin: "1.5em 0 1.5em 0"
      }}><b>{text}</b></p>
      <CallToAction link="/kontakt" text="Jetzt informieren" />
    </>
  )
}

export default Outlook