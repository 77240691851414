import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const CallToAction = ({ text = "Button", link = "/" }) => {

  return (
    <Link
      to={link}
      activeClassName="active"
      className="btn btn-primary btn-lg"
    >
      <FontAwesomeIcon icon={faArrowRight} size={"sm"} alt={text} style={{ marginRight: "8px" }} /> {text}

    </Link>
  )
}

export default CallToAction